import styled from "styled-components";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { red } from "../../styles";

const Page = styled.main`
  text-align: left;
  color: #3c3b3b;
`;

const PageTitle = styled.h1`
  color: rgb(0, 55, 103);
`;

const Link = styled.a`
  margin-left: 5px;
  cursor: pointer;
`;

const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;

const Intro = styled.p`
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 40px;
`;

const StyledButton = styled.button`
  color: white;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid ${red};
  border-radius: 0.375rem;
  background-color: ${red};
  opacity: 0.9;
  margin-bottom: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: #212529;
    border-color: #212529;
  }
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    opacity: 0.8;
  }
`;

const Button = ({ onSubmit, children, loading }) => {
  return (
    <StyledButton className="submit-btn" onClick={onSubmit}>
      {!loading ? (
        children
      ) : (
        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
      )}
    </StyledButton>
  );
};

Button.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
};

const collectionMap = {
  1: "Programs",
  2: "Applications",
  3: "Applications By Component Group",
  4: "Tasks",
  5: "Reviews",
};

function SMAdmin() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [year, setYear] = useState("2024");

  const updateFunction = (collection, id) => {
    setFetchError(null);
    setSelectedButton(id);
    fetch(`/api/updateSMCollection?collection=${collection}&year=${year}`)
      .then(() => {
        setSelectedButton(null);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        if (id !== 3)
          setFetchError(
            `Error fetching the ${collectionMap[id]} from SurveyMonkey. Please try again`
          );
        else
          setFetchError(
            `Error updating the ${collectionMap[id]}. Please try again`
          );
      });
  };
  const exportFile = (id) => {
    setSelectedButton(id);
    const link = document.createElement("a");
    link.href = `/api/getMasterReport?exportFile=true&year=${year}`;
    link.click();
    setTimeout(() => {
      setSelectedButton(null);
    }, 3000);
  };
  const change = (e) => {
    setYear(e.target.value);
  };

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const response = await fetch(`/api/isAdmin`, {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 403) {
          setError("You do not have access to this page");
        } else if (response.status === 200) {
          setError(null);
        } else {
          setError("An unexpected error occurred");
        }
      } catch (e) {
        setError("An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    checkAdminStatus();
  }, []);

  if (isLoading) {
    return (
      <Page>
        <Loading>
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        </Loading>
      </Page>
    );
  } else if (error) {
    return (
      <span className="row">
        <span className="alert alert-danger" role="alert">
          {error}
        </span>
        <br />
      </span>
    );
  }

  return (
    <Page>
      <PageTitle>SOT Apply - Master Report Page for Administrators</PageTitle>

      <Intro>
        This page outlines the steps to populate a cloud database (Azure Cosmos
        DB) and generate a master report listing the Component Group award
        recipients for the selected year. The report is then used by Component
        Group Staff Liaisons to track awards and their corresponding recipients.
      </Intro>

      <p>
        <strong>How to: </strong>
        SOT staff can download the Master Report for the selected award cycle
        year (via the dropdown) from SOT Apply and update all databases in Azure
        by clicking the buttons in consecutive order, starting with Step #1.
        Alternatively, they can choose to update only the Applications (starting
        at Step #2). If applications are no longer being accepted because all
        awards have been closed, users can update only the Reviews or Tasks
        (Steps #4 and #5) to download information from Award
        Agreements/Endowment Questionnaires or Component Group officer reviews.
      </p>
      <p>
        At the end, a new report must be exported each time updates are
        downloaded (Step #6).
      </p>
      <p>
        <strong>Additional User Documentation:</strong>
      </p>
      <ul>
        <li>
          <Link
            href="https://aimhq.sharepoint.com/:x:/s/SOT/EZh2_lWpd2hIl7WvuqSFrzAB3r9VPsspE2E4zv9-8GMnFA?e=tJq7nD"
            target="_blank"
          >
            Mapping of report fields
          </Link>
        </li>
        <li>
          <Link
            href="https://aimhq.sharepoint.com/:w:/s/SOT/EUxYjX8dhRFFiL0O-SNx6JUBDkR911iJSozbW-LXXxGX8w?e=kuD45E"
            target="_blank"
          >
            Master report SOP
          </Link>
        </li>
      </ul>
      <br />
      <p>
        <strong>Award Cycle Years:</strong>
      </p>
      <p>
        Browse through the list of available years and select the award year
        cycle (July through June) for which you need to generate the Master
        Report. The year cycle you select will be used as a parameter for
        filtering the data feeding the report.
      </p>
      <select id="select_year" onChange={change} value={year}>
        <option value={2024}>2024-2025</option>
        <option value={2025}>2025-2026</option>
        <option value={2026}>2026-2027</option>
        <option value={2027}>2027-2028</option>
        <option value={2028}>2028-2029</option>
        <option value={2029}>2029-2030</option>
      </select>

      <hr style={{ margin: "40px 0px" }}></hr>

      <h3>1. Update all SOT Apply Awards (Programs)</h3>
      <p>
        {`Use the button "Update Programs DB and Filter by Component Group" to
        download all the most current award (program) information from all SOT
        Apply awards and store it in the Azure Cosmos DB. Once the information
        is downloaded, a second script will separate those awards that are CG
        awards into a separate collection.`}
      </p>
      <p>
        <strong>Technical Description:</strong>{" "}
        {`This will fetch programs from
        survey monkey API (https://sotapply.toxicology.org/api/programs/) to
        store them in Azure's Cosmos DB and then filter them by Component Group
        in a separate collection. The filter that identifies which awards are CG
        awards is the Extras &gt; Custom Field "Component Group Type" field.`}
      </p>
      <p>
        All programs are stored in the smPrograms collection, and the filtered
        CG programs are stored in the smCGPrograms collection.
      </p>
      <Button
        onSubmit={() => updateFunction("programs", 1)}
        loading={selectedButton === 1 && !fetchError}
      >
        Update Programs DB and filter by Component Group
      </Button>
      <br />
      {fetchError && selectedButton === 1 && (
        <span className="row">
          <span className="alert alert-danger" role="alert">
            {fetchError}
          </span>
        </span>
      )}
      <br />
      <h3>2. Download all SOT Apply Applications</h3>
      <p>
        {`The "Update Applications DB" button will download all applications
        (including non-CG awards) and store them in the Azure DB.`}
      </p>

      <p>
        <strong>Technical Description:</strong>{" "}
        {`This will fetch all the
        applications from survey monkey API (/api/applications/) to store them
        in Azure's Cosmos DB smApplications collection.`}
      </p>
      <Button
        onSubmit={() => updateFunction("applications", 2)}
        loading={selectedButton === 2 && !fetchError}
      >
        Update Applications DB
      </Button>
      <br />
      {fetchError && selectedButton === 2 && (
        <span className="row">
          <span className="alert alert-danger" role="alert">
            {fetchError}
          </span>
          <br />
        </span>
      )}
      <br />
      <h3>3. Separate the CG Applications</h3>
      <p>
        {`The "Filter Applications by Component Group" button will identify those
        applications that belong to a CG award and will store them in a separate
        collection. Four additional programs are included in this list as they
        are considered Endowment awards: Perry J. Gehring Diversity Student
        Travel Award; Diversity Initiatives Career Development Award; Graduate
        Intern Fellowship in Toxicology Award (GIFT); and Mehendale Welcome
        Award.`}
      </p>

      <p>
        <strong>Technical Description:</strong> This will filter all the
        applications based on whether the program they belong to is a CG program
        (or is part of the additional 4 programs) and store them in the
        smCGApplications collection.
      </p>
      <Button
        onSubmit={() => updateFunction("cgApplications", 3)}
        loading={selectedButton === 3 && !fetchError}
      >
        Filter Applications by Component Group
      </Button>
      <br />
      {fetchError && selectedButton === 3 && (
        <span className="row">
          <span className="alert alert-danger" role="alert">
            {fetchError}
          </span>
          <br />
        </span>
      )}
      <br />
      <h3>4. Download Application Tasks</h3>

      <p>
        {`When applications are downloaded, they do not include the applicants'
        responses. Instead, the download contains general information about the
        application, such as its creation date, the associated program, and
        other relevant details. Most of this information—similar to what is
        found in the smApplication and smCGApplications collections—can also be
        accessed on the Program Settings page in SOT Apply.`}
      </p>

      <p>
        {`To view the actual application responses, you need to download the
        application tasks for all submissions. Clicking the "Update Tasks"
        button will download or update all tasks associated with the CG
        applications. This includes tasks from the original application, as well
        as those from the Award Agreement and Endowment Questionnaire stages.`}
      </p>

      <p>
        <strong>Technical Description:</strong>{" "}
        {`This will fetch all the Tasks by
        application id (/api/applications/{id}/tasks/) from survey monkey API to
        store them in Azure's Cosmos DB. A loop goes through all the ids. The
        stored tasks can be found in the smCGApplicationTasks collection.`}
      </p>
      <Button
        onSubmit={() => updateFunction("smCGTasks", 4)}
        loading={selectedButton === 4 && !fetchError}
      >
        Update Tasks
      </Button>
      <br />
      {fetchError && selectedButton === 4 && (
        <span className="row">
          <span className="alert alert-danger" role="alert">
            {fetchError}
          </span>
          <br />
        </span>
      )}
      <br />
      <h3>5. Download Application Reviews</h3>
      <p>
        {`Similar to tasks, reviews must be downloaded separately. Clicking the
        "Update Reviews" button will download or update the application reviews
        for all submissions. The downloaded reviews will include feedback from
        all review stages, including staff and officer reviews.`}
      </p>

      <p>
        <strong>Technical Description: </strong>
        {`This will fetch all the Reviews by application id
        (/api/reviews/?application={id}) from survey monkey API to store them in
        Azure's Cosmos DB smCGApplicationReviews collection.`}
      </p>
      <Button
        onSubmit={() => updateFunction("smCGReviews", 5)}
        loading={selectedButton === 5 && !fetchError}
      >
        Update Reviews
      </Button>
      <br />
      {fetchError && selectedButton === 5 && (
        <span className="row">
          <span className="alert alert-danger" role="alert">
            {fetchError}
          </span>
          <br />
        </span>
      )}
      <br />
      <h3>6. Download Full Master Report</h3>

      <p>
        {`Clicking the "Export Master Report" button will merge all the
        collections mentioned above and generate a CSV file containing the
        filtered data from Azure. Please note that if an application has no
        completed tasks or reviews in Apply, it will be excluded from the merge
        process and will not appear in the report.`}
      </p>
      <p>
        Additionally, this report will not include updated data unless the Azure
        collections are refreshed by running the buttons above.
      </p>

      <p>
        <strong>Technical Description:</strong> The button below will join the
        Reviews with the Applications and Tasks data using the application ID
        and export the data as a CSV file. The report data is stored in the
        MasterReport collection
      </p>
      <Button onSubmit={() => exportFile(6)} loading={selectedButton === 6}>
        Generate and Export Master Report
      </Button>
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
}

export default SMAdmin;
