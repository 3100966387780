import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { blue, orange } from "../../styles";
import ReactMarkdown from "react-markdown";

const AwardHeader = styled.h2`
  border-bottom: 5px solid ${blue};
`;
const AwardName = styled.span`
  text-transform: capitalize;
  color: ${orange};
  font-weight: bold;
  font-size: 26px;
`;

const AwardWrapper = styled.div`
  margin: 0 0 60px;
  @media print {
    margin: 0;
  }
`;

const AwardeeWrapper = styled.div`
  color: black;
  margin-top: 20px;
  margin-bottom: 20px;
  @media print {
    page-break-after: always;
    font-size: 10px;
  }
`;
const Photo = styled.img`
  border-radius: 12px;
  max-width: 65%;
`;
const InfoLabel = styled.span`
  font-weight: bold;
  color: ${blue};
`;
const LeftSection = styled.div`
  padding: 0;
  @media print {
    width: 25%;
  }
`;
const RightSection = styled.div`
  padding-left: 0;
  @media print {
    width: 75%;
  }
`;

const applicantDegreeAndAffiliation = {
  "91524266": {
      "degrees": "BS",
      "affiliation": "Rutgers University—New Brunswick"
  },
  "95487708": {
      "degrees": "MSc",
      "affiliation": "McGill University"
  },
  "99302052": {
      "degrees": "PhD",
      "affiliation": "University of Rochester School of Medicine and Dentistry"
  },
  "101412639": {
      "degrees": "BS",
      "affiliation": "University of North Carolina at Chapel Hill"
  },
  "124817623": {
      "degrees": "",
      "affiliation": "University of Cambridge"
  },
  "126553229": {
      "degrees": "BS",
      "affiliation": "University of Alabama at Birmingham"
  },
  "126553259": {
      "degrees": "PhD",
      "affiliation": "University of Michigan"
  },
  "126553264": {
      "degrees": "BS",
      "affiliation": "University of Rhode Island"
  },
  "126572815": {
      "degrees": "MS",
      "affiliation": "Rutgers University of New Jersey"
  },
  "126572888": {
      "degrees": "PhD",
      "affiliation": "Rutgers, The State University of New Jersey"
  },
  "126572921": {
      "degrees": "DVM, MSc",
      "affiliation": "University of California Davis"
  },
  "126572942": {
      "degrees": "MS",
      "affiliation": "Wright State University"
  },
  "126573063": {
      "degrees": "MS",
      "affiliation": "University of California Irvine"
  },
  "126573287": {
      "degrees": "BS",
      "affiliation": "Rutgers, The State University of New Jersey"
  },
  "126573320": {
      "degrees": "PhD",
      "affiliation": "University of South Carolina School of Medicine"
  },
  "126573410": {
      "degrees": "BS",
      "affiliation": "University of Wisconsin-Madison"
  },
  "126573465": {
      "degrees": "",
      "affiliation": "University of Toronto "
  },
  "126573583": {
      "degrees": "PhD",
      "affiliation": "The Ohio State University"
  },
  "126573614": {
      "degrees": "BS",
      "affiliation": "Oregon State University"
  },
  "126573710": {
      "degrees": "PhD",
      "affiliation": "University of Rochester"
  },
  "126574082": {
      "degrees": "MSc, PhD",
      "affiliation": "University of Louisville"
  },
  "126574289": {
      "degrees": "BS",
      "affiliation": "University of Illinois at Urbana Champaign"
  },
  "126574747": {
      "degrees": "BS",
      "affiliation": "Rutgers University—New Brunswick"
  },
  "126575137": {
      "degrees": "BS",
      "affiliation": "Rutgers, The State University of New Jersey"
  },
  "126575161": {
      "degrees": "MS, PhD",
      "affiliation": "RTI International"
  },
  "126575217": {
      "degrees": "BS",
      "affiliation": "University of Alabama at Birmingham"
  },
  "126575254": {
      "degrees": "PharmD",
      "affiliation": "University of Milan"
  },
  "126575445": {
      "degrees": "MS",
      "affiliation": "Texas Tech University"
  },
  "126575531": {
      "degrees": "PhD",
      "affiliation": "The University of Montana"
  },
  "126575567": {
      "degrees": "MS",
      "affiliation": "New York University "
  },
  "126575594": {
      "degrees": "PharmD",
      "affiliation": "Rutgers, The State University of New Jersey"
  },
  "126575720": {
      "degrees": "BA",
      "affiliation": "University of North Carolina at Chapel Hill"
  },
  "126575766": {
      "degrees": "PhD",
      "affiliation": "US EPA"
  },
  "126576001": {
      "degrees": "PhD",
      "affiliation": "Helmholtz Centre for Environmental Research"
  },
  "126576067": {
      "degrees": "MS",
      "affiliation": "University of Washington"
  },
  "126576075": {
      "degrees": "MS",
      "affiliation": "Texas A&M University"
  },
  "126576165": {
      "degrees": "BS",
      "affiliation": "The Ohio State University"
  },
  "126576176": {
      "degrees": "MD, PhD",
      "affiliation": "University of Louisville"
  },
  "126576182": {
      "degrees": "MS",
      "affiliation": "Texas A&M University"
  },
  "126576234": {
      "degrees": "BS",
      "affiliation": "University of California Davis"
  },
  "126576428": {
      "degrees": "BS",
      "affiliation": "Louisiana State University"
  },
  "126576519": {
      "degrees": "MSc",
      "affiliation": "University of Louisville"
  },
  "126576711": {
      "degrees": "MSc",
      "affiliation": "University of California Irvine"
  },
  "126576818": {
      "degrees": "PhD",
      "affiliation": "Southern University and A&M College"
  },
  "126576844": {
      "degrees": "PhD",
      "affiliation": "University of Louisville"
  },
  "126576852": {
      "degrees": "BS",
      "affiliation": "University of North Carolina at Chapel Hill"
  },
  "126576962": {
      "degrees": "BS",
      "affiliation": "University of Maine"
  },
  "126577014": {
      "degrees": "PhD",
      "affiliation": "Harvard Medical School and Beth Israel Deaconess Medical Center"
  },
  "126577376": {
      "degrees": "PhD",
      "affiliation": "US EPA"
  },
  "126577638": {
      "degrees": "BS",
      "affiliation": "St John's University"
  },
  "126577643": {
      "degrees": "PhD",
      "affiliation": "US EPA"
  },
  "126577685": {
      "degrees": "PhD",
      "affiliation": "North Carolina State University"
  },
  "126577755": {
      "degrees": "BS",
      "affiliation": "Rutgers, The State University of New Jersey"
  },
  "126577783": {
      "degrees": "BS",
      "affiliation": "Rutgers University—New Brunswick"
  },
  "126577907": {
      "degrees": "MSc",
      "affiliation": "University of California Irvine"
  },
  "126577986": {
      "degrees": "PhD",
      "affiliation": "University of Massachusetts Amherst"
  },
  "126578027": {
      "degrees": "BS",
      "affiliation": "University of North Carolina at Chapel Hill"
  },
  "126578059": {
      "degrees": "BS",
      "affiliation": "The Ohio State University"
  },
  "126578134": {
      "degrees": "BS",
      "affiliation": "The University of Utah"
  },
  "126578162": {
      "degrees": "BSPH",
      "affiliation": "University of North Carolina at Chapel Hill"
  },
  "126578170": {
      "degrees": "PhD, MSc",
      "affiliation": "Indiana University Bloomington"
  },
  "126578759": {
      "degrees": "MSc, PhD",
      "affiliation": "Louisiana State University"
  },
  "126578804": {
      "degrees": "MS",
      "affiliation": "University of Massachusetts Amherst"
  },
  "126578888": {
      "degrees": "MSc",
      "affiliation": "West Virginia University"
  },
  "126578984": {
      "degrees": "MS",
      "affiliation": "University of California Irvine"
  },
  "126579004": {
      "degrees": "BS",
      "affiliation": "University of Louisville"
  },
  "126579024": {
      "degrees": "PhD",
      "affiliation": "Texas A&M University"
  },
  "126579166": {
      "degrees": "BS",
      "affiliation": "University of North Carolina at Chapel Hill"
  },
  "126579199": {
      "degrees": "BS",
      "affiliation": "Michigan State University"
  },
  "126579296": {
      "degrees": "MS",
      "affiliation": "University of Louisville"
  },
  "126579344": {
      "degrees": "PhD",
      "affiliation": "University of Louisville"
  },
  "126579346": {
      "degrees": "PhD",
      "affiliation": "Louisiana State University"
  },
  "126579385": {
      "degrees": "",
      "affiliation": "Colgate-Palmolive Company"
  },
  "126579508": {
      "degrees": "DVM, MS, DACVP ",
      "affiliation": "Auburn University"
  },
  "126579540": {
      "degrees": "PhD",
      "affiliation": "University of Louisville"
  },
  "126579607": {
      "degrees": "PhD",
      "affiliation": "Texas A&M University"
  },
  "126594769": {
      "degrees": "BS",
      "affiliation": "University of Cambridge"
  },
  "126654168": {
      "degrees": "PhD",
      "affiliation": "Clemson University"
  },
  "126664278": {
      "degrees": "MSc",
      "affiliation": "University of California Irvine"
  },
  "126675720": {
      "degrees": "BS",
      "affiliation": "Rutgers Robert Wood Johnson Medical School"
  },
  "126691226": {
      "degrees": "BS",
      "affiliation": "University of Kansas Medical Center"
  },
  "126718661": {
      "degrees": "BA",
      "affiliation": "University of Rochester School of Medicine and Dentistry"
  },
  "126845102": {
      "degrees": "MS, BS",
      "affiliation": "Purdue University"
  },
  "126888162": {
      "degrees": "MS, PhD",
      "affiliation": "University of Florida"
  },
  "126899396": {
      "degrees": "BS",
      "affiliation": "McGill University"
  },
  "126916439": {
      "degrees": "",
      "affiliation": "University of Puerto Rico Mayagüez"
  },
  "126923997": {
      "degrees": "MS, PhD",
      "affiliation": "Weill Cornell Medicine"
  },
  "126929149": {
      "degrees": "MS",
      "affiliation": "Helmholtz Centre for Environmental Research"
  },
  "126939158": {
      "degrees": "MSc, PhD",
      "affiliation": "University of Georgia"
  },
  "126946466": {
      "degrees": "BS",
      "affiliation": "Texas Tech University Health Sciences Center"
  },
  "126953718": {
      "degrees": "MS, PhD",
      "affiliation": "CINVESTAV"
  },
  "126959117": {
      "degrees": "MS",
      "affiliation": "Kansas University Medical Center"
  },
  "126985043": {
      "degrees": "BS",
      "affiliation": "University of South Carolina School of Medicine"
  },
  "127001833": {
      "degrees": "BS",
      "affiliation": "Rutgers, The State University of New Jersey"
  },
  "127002357": {
      "degrees": "BS",
      "affiliation": "DePaul University"
  },
  "127134556": {
      "degrees": "PhD",
      "affiliation": "Johns Hopkins University"
  },
  "127137975": {
      "degrees": "MS",
      "affiliation": "Oregon State University"
  },
  "127146515": {
      "degrees": "BS Pharm.",
      "affiliation": "National Taiwan University"
  },
  "127165877": {
      "degrees": "PhD",
      "affiliation": "University of Georgia"
  },
  "127183215": {
      "degrees": "BS",
      "affiliation": "Oregon State University"
  },
  "127219059": {
      "degrees": "BS",
      "affiliation": "Rutgers, The State University of New Jersey"
  },
  "127243912": {
      "degrees": "MSc, PhD",
      "affiliation": "Universidade de São Paulo"
  },
  "127284052": {
      "degrees": "",
      "affiliation": "NIEHS"
  },
  "127287883": {
      "degrees": "BS",
      "affiliation": "University of Toronto"
  },
  "127342735": {
      "degrees": "MS",
      "affiliation": "CINVESTAV"
  },
  "127346852": {
      "degrees": "PhD",
      "affiliation": "Universidade de São Paulo"
  },
  "127419574": {
      "degrees": "PhD",
      "affiliation": "University of Liege"
  },
  "127419675": {
      "degrees": "BA",
      "affiliation": "Michigan State University"
  },
  "127434862": {
      "degrees": "",
      "affiliation": "Texas A&M University"
  },
  "127457674": {
      "degrees": "BS",
      "affiliation": "ORISE US EPA"
  },
  "128363717": {
      "degrees": "MS",
      "affiliation": "University of Iowa"
  }
}

const Awardee = ({ awardee }) => {
  const researchComplete =
    awardee.reasearch_description !== "" &&
    awardee.reasearch_description !== "INCOMPLETE";
  const reactionComplete =
    awardee.reaction !== "" && awardee.reaction !== "INCOMPLETE";
  
  const { degrees, affiliation } = applicantDegreeAndAffiliation[awardee.applicantId] || {};
  return (
    <React.Fragment>
      <AwardeeWrapper className="row">
        <LeftSection className="col-xs-12 col-md-6 col-lg-3">
          <div className="row">
            <div
              className="col-xs-12"
              style={{ textAlign: "center", padding: 0 }}
            >
              <Photo
                src={`/images/headshots/2024/${awardee.applicantId}_${awardee.last_name}.jpg`}
                alt={`Photograph of ${awardee.first_name} ${awardee.last_name}`}
                className="img-fluid"
              />
            </div>
          </div>
        </LeftSection>

        <RightSection className="col-lg-9 col-md-6 col-xs-12">
          <p>
            <InfoLabel>Recipient:</InfoLabel> {awardee.first_name}{" "}
            {awardee.last_name}
            <br />
            <InfoLabel>Highest Degree:</InfoLabel> {degrees}<br />
            <InfoLabel>Institution/Affiliation:</InfoLabel> {affiliation}
            <br />
          </p>
          {researchComplete && <p>{awardee.reasearch_description}</p>}

          {reactionComplete && (
            <ReactMarkdown>{awardee.reaction}</ReactMarkdown>
          )}
        </RightSection>
      </AwardeeWrapper>
    </React.Fragment>
  );
};

const AwardeeMemo = React.memo(Awardee);
function AwardCard({ awardName = "", awardeeList = [] }) {
  return (
    <React.Fragment>
      <AwardWrapper className="col-xs-12">
        <div className="row">
          <div className="col-xs-12">
            <AwardHeader>
              <AwardName>{awardName}</AwardName>
            </AwardHeader>
          </div>
        </div>

        {awardeeList.length > 0 &&
          awardeeList.map((awardee, index) => (
            <AwardeeMemo awardee={awardee} key={`${awardee._id}_${index}`} />
          ))}
      </AwardWrapper>
    </React.Fragment>
  );
}

AwardCard.propTypes = {
  awardName: PropTypes.string,
  awardeeList: PropTypes.array,
};

Awardee.propTypes = {
  awardee: PropTypes.object,
};

export default React.memo(AwardCard);
